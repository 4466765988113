import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/context/privateRoute"
import Profile from "../../components/app/profile"
import AppLayout from "../../components/app/app-components/layout"

const AppExams = ({ path }) => {
  return (
    <AppLayout title="Profile" path={path} isBackButton={true}>
      <Router>
        <PrivateRoute path="/app/profile" component={Profile} />
      </Router>
    </AppLayout>
  )
}

export default AppExams
