import React from "react"
import { BookmarkIcon } from "@heroicons/react/solid"

export function Enrollments({ subscription }) {
  return (
    <div className="relative w-full p-4 overflow-hidden bg-white shadow rounded-xl md:w-80 dark:bg-gray-800">
      <div className="flex items-center justify-between w-full mb-4">
        <p className="font-semibold text-gray-800 dark:text-white ">
          My Subscriptions
        </p>
        <a
          href="#"
          className="items-center hidden text-xs text-gray-300 border-0 hover:text-gray-600 dark:text-gray-50 dark:hover:text-white focus:outline-none"
        >
          VIEW ALL
        </a>
      </div>

      {subscription?.map(subs => (
        <div
          key={subs?.subscriptionId}
          className="flex items-center justify-between w-full mb-6 bg-white rounded-md "
        >
          <div className="flex items-center justify-center ">
            <BookmarkIcon className="w-5 h-5 mr-1" />
            <p className="dark:text-white">{subs?.segment?.sector}</p>
          </div>
          <div className="px-2 py-1 text-xs bg-yellow-400 rounded">
            {subs?.subscription_plan?.name}
          </div>
        </div>
      ))}
    </div>
  )
}
