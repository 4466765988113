import React, { useState } from "react"
import { navigate } from "gatsby"
import { LogoutIcon, DotsHorizontalIcon } from "@heroicons/react/outline"
import { FcFeedback } from "react-icons/fc"
import { UserCard } from "./UserCard"
import { Enrollments } from "./Enrollments"
import Feedback from "./feedback"
import { useAuth } from "../../context/auth"
import { useQuery } from "react-query"
import * as api from "../../context/api"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import { motion } from "framer-motion"
import { myAnimations } from "../../context/framer-animations"

const Profile = () => {
  const { logout, currentUser } = useAuth()
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const {
    data: student,
    isLoading: isLoadingStudent,
    isError: isErrorStudent,
  } = useQuery(["getStudent", currentUser?.email], () =>
    api.getStudent(currentUser?.email)
  )

  const {
    data: subscription,
    isLoading,
    isError,
  } = useQuery("getSubscription", () => api.getSubscriptions(student?.email))

  if (isError || isErrorStudent) return <p>Something went wrong</p>

  const handleLogout = async e => {
    await logout()
    navigate("/login")
  }

  return (
    <motion.div {...myAnimations.slideright} className="flex-grow mx-4 mb-10">
      <div className="mt-4">
        <UserCard
          student={student && student[0]}
          subscription={subscription && subscription[0]}
        />
      </div>
      <div className="hidden my-4">
        <Enrollments subscription={subscription} />
      </div>
      <hr className="my-6 dark:border-gray-600" />
      <button
        className="flex items-center hidden w-full px-4 py-2 mt-2 text-gray-600 transition-colors duration-200 transform bg-white shadow rounded-xl dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
        onClick={handleLogout}
      >
        <DotsHorizontalIcon className="w-5 h-5" />
        <span className="mx-4">Change Password</span>
      </button>
      <button
        className="flex items-center w-full px-4 py-2 mt-2 text-gray-600 transition-colors duration-200 transform bg-white shadow rounded-xl dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
        onClick={handleLogout}
      >
        <LogoutIcon className="w-5 h-5" />
        <span className="mx-4">Logout</span>
      </button>
      <button
        className="flex items-center w-full px-4 py-2 mt-2 text-gray-600 transition-colors duration-200 transform bg-white shadow rounded-xl dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
        onClick={onOpenModal}
      >
        <FcFeedback className="w-5 h-5" />
        <span className="mx-4">Submit Feedback</span>
      </button>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <Feedback onCloseModal={onCloseModal} studentId={currentUser?.email} />
      </Modal>
    </motion.div>
  )
}

export default Profile
